import '../../styles/footer.css';
import React from 'react';

function Footer() {
  return (
    <div className="footer">
        <div className="footer_logo"></div>
        <div className="footer_contat">
          <p>courriel: info@netdra.com</p>
          <p>téléphone: 514 833-3760</p>
        </div>
    </div>
  );
}

export default Footer;