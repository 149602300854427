export const serviceList = [
    {
        key: 1,
        title: "Vente",
        texte: (  
            <div> 
                <p>Nous offrons, à prix compétitif, les équipements dont vous avez besoin pour vos besoins en informatique.</p>
                <ul>
                    <li>Ordinateur</li>
                    <li>Serveur</li>
                    <li>Imprimante</li>
                    <li>Routeur</li>
                    <li>...</li>
                </ul>
                <p>Contactez-nous pour obtenir une soumission.</p>
                <p>Selon la disponibilité, nous offrons la location d’ordinateurs avec un service d’assistance.</p>
            </div>
            )
    },

    {
        key: 2,
        title: "Assistance",
        texte: (  
            <div> 
                <p>Assistance informatique applicative et matériel à la demande ou avec abonnement mensuel.</p>
                <h3>Sur demande</h3>
                <ul>
                    <li>Avec rendez-vous
                    </li>
                </ul>
                <h3>Abonnement mensuel</h3>
                <ul>
                    <li>Demande d’aide illimitée par poste de travail</li>
                    <li>Délai de réponse de 4 heures ouvrables ou moins</li>
                </ul>
            </div>
            )
    },
    {
        key: 3,
        title: "Administration",
        texte: (  
            <div> 
                <p>Administration et maintenance de vos serveur.</p>
                <ul>
                    <li>Exchange</li>
                    <li>Windows serveur</li>
                    <li>Active directory</li>
                    <li>SQL Serveur</li>
                    <li>Gestion de sauegardes</li>
                    <li>...</li>
                </ul>
            </div>
            )
    },
    {
        key: 4,
        title: "Conception",
        texte: (  
            <div> 
                <p>Conception personnalisée d’application(web, mobile ou burreau), de bases de données ainsi que de rapport.</p>
                <ul>
                    <li>Conception d’application (C++, C#, Python, Java ...)</li>
                    <li>Site web\application web (fullstack, NodeJS, React, VueJS ...</li>
                    <li>Base de données (SQL Server, MySQL, PLSQL ...) </li>
                    <li>Rapport (Crystal report)</li>
                    <li>...</li>
                </ul>
            </div>
            )
    }
]