import * as React from 'react';
import '../../styles/card.css';

function Card(props) {
  return (
    <div className="card">
      <div className="inside_card">
        <h1>{props.title}</h1>
        {props.texte}
      </div>
    </div>
  );
}

export default Card;