import * as React from 'react';
import Card from '../../components/card';
import { serviceList } from '../../datas/card_data' 
import '../../styles/home.css';

function Home() {
  return (
    <div className="home">
      {serviceList.map((service) => 
        (
          <Card className="card" key={service.key} title={service.title} texte={service.texte} icon=""/>
        )
      )}
    </div>
  );
}

export default Home;
