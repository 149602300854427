import '../../styles/header.css';
import logo from '../../assets/logo_red.svg';
import React from 'react';


function Header() {
  return (
    <div className="header">
      <div className="menu"></div>
      <div className="header_container">
        <div className="logo">
          <img className="img" src={logo}  alt="logo" />
          <p className="titre">Consultant informatique</p>
        </div>
        <div className="right_form"></div>
        <div className="left_form"></div>
        <div className="center_form"></div>
        <a className="ref" href='https://fr.freepik.com/photos/cafe'>Café photo créé par rawpixel.com - fr.freepik.com</a>
      </div>
    </div>
  );
}

export default Header 